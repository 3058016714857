<messages>["@/app/i18n/common/GenericObject", "./Domain"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <v-row justify="space-between">
    <v-col cols="12" class="py-0">
      <v-row>
        <v-col class="pb-0" align-self="center">
          <slot name="controls">
            <v-spacer/>
          </slot>
        </v-col>
        <v-col class="justify-end shrink pb-0" align-self="center">
          <!-- "show order" button -->
          <order-dialog
            v-if="domain.orderId"
            :order-id="domain.orderId"
            :object-id="domain.name"
            type="domain">
            <template #activator="{ on }">
              <v-btn
                small
                class="mx-0 my-2 elevation-0"
                v-on="on">
                {{ history
                  ? $t ('general.button.showOrder')
                  : $t ('general.button.showCurrentOrder') }}
              </v-btn>
            </template>
          </order-dialog>
        </v-col>
      </v-row>
    </v-col>

    <!-- basic domain data -->
    <v-col
      class="d-block"
      cols="12" md="6">
      <data-card :title="$t ('view.section.basic')">
        <name-value-block :items="basicData"/>
      </data-card>
    </v-col>

    <!-- domain dates -->
    <v-col
      class="d-flex"
      cols="12" md="6">
      <data-card :title="$t ('view.section.lifecycle')">
        <name-value-block :items="lifecycle"/>
      </data-card>
    </v-col>

    <!-- domain contacts -->
    <v-col
      class="d-block"
      cols="12">
      <data-card :title="$t ('view.section.contacts')">
        <v-row v-if="contacts.length">
          <v-col
            v-for="contact in contacts"
            :key="`${contact.handle}-${contact.role}`"
            class="justify-space-between pt-0"
            cols="12" sm="6" lg="4">
            <contact-card
              :history="history"
              :contact="contact"/>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col v-t="'view.noContacts'"/>
        </v-row>
      </data-card>
    </v-col>

    <!-- domain hosts -->
    <v-col
      class="d-flex"
      cols="12" md="6">
      <data-card :title="$t ('view.section.hosts')">
        <span
          v-if="hosts.length === 0"
          v-t="'view.noHosts'"/>
        <name-value-block
          v-else
          :items="hosts"/>
      </data-card>
    </v-col>

    <!-- domain provider chains -->
    <v-col
      class="d-flex"
      cols="12" md="6">
      <data-card :title="$t ('view.section.providerChains')">
        <name-value-block :items="providerChains"/>
      </data-card>
    </v-col>

    <!-- domain states -->
    <v-col
      v-if="type === TYPES.DOMAIN"
      class="d-flex"
      cols="12" md="6">
      <data-card :title="$t ('view.section.states')">
        <span
          v-if="domain.states.length === 0"
          v-t="'view.noStates'"/>
        <name-value-block
          v-else
          :items="domainStates"/>
      </data-card>
    </v-col>

    <!-- DNSSEC data -->
    <v-col
      class="d-flex"
      cols="12" md="6">
      <data-card :title="$t ('view.section.dnssec')">
        <base-dialog
          v-if="domain.dnssecData.length > 0"
          v-model="dnssecDialog"
          mw1>
          <template #activator="{ on }">
            <a v-on="on">
              <span>
                {{
                  $tc ('view.dnssecData', domain.dnssecData.length,
                       {count: domain.dnssecData.length})
                }}
              </span>
            </a>
          </template>
          <v-card>
            <v-card-title>
              <span>
                <strong>
                  {{
                    $t ('view.dialog.dnssec.title', {domain: domain.name})
                  }}
                </strong>
              </span>
            </v-card-title>
            <v-card-text class="px-6 pb-6">
              <domain-dnssec-data
                v-model="domain.dnssecData"
                readonly/>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn
                v-t="'general.button.close'"
                text
                @click.stop="dnssecDialog=false"/>
            </v-card-actions>
          </v-card>
        </base-dialog>
        <span
          v-else
          v-t="'view.noDnssecData'"/>
      </data-card>
    </v-col>

    <!-- optional domain data -->
    <v-col
      v-for="section in optionalSections.filter (s => s.show)"
      :key="section.l"
      class="d-flex"
      cols="12" md="6">
      <data-card :title="$t (`view.section.${section.l}`)">
        <name-value-block :items="section.i.sort ((i1, i2) =>
          i1.name.localeCompare (i2.name))"/>
      </data-card>
    </v-col>

    <!-- Domain Variants data-->
    <v-col
      v-if="variants.length && (!history || registryMetadata.variantsAllowed)"
      class="d-flex"
      cols="12">
      <data-card :title="$t ('view.section.variants')">
        <v-row no-gutters>
          <v-col
            v-for="variant in variants"
            :key="variant.aname"
            cols="12" sm="6" md="3" xl="2">
            <v-card
              height="100%"
              width="100%"
              flat
              class="pa-0 ma-0">
              <v-card-title
                primary-title
                class="py-0 pl-0">
                <div>
                  <div class="text-subtitle-2">
                    <router-link
                      v-if="variant.versionId"
                      :to="{
                        name: 'domain.view.version',
                        params: {vid: variant.versionId}
                      }">
                      <h3 :class="{deletedItemLink: variant.deleted}">
                        {{ variant.uname }}
                      </h3>
                    </router-link>
                    <h3
                      v-else
                      :class="{deletedItemText: variant.deleted}">
                      {{ variant.uname }}
                    </h3>
                  </div>
                  <div
                    v-if="variant.aname && variant.uname !== variant.aname "
                    class="cgwng-subheading">
                    ({{ variant.aname }})
                  </div>
                </div>
              </v-card-title>
            </v-card>
          </v-col>
          <v-col
            v-if="domain.extraData.variants.length > 8"
            class="mt-2"
            cols="12" sm="6" md="3">
            <a
              href=""
              @click.prevent="showAllVariants = !showAllVariants">
              {{ showAllVariants
                ? $t ('view.label.showless')
                : $t ('view.label.showmore') }}
            </a>
          </v-col>
        </v-row>
      </data-card>
    </v-col>
  </v-row>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {EmptyMark, toCamelCase} from '@/app/utils/string'
  import {mapGetters} from 'vuex'

  import registryCommands from '@/app/core/mixins/RegistryCommands'

  import BaseDialog from '@/app/core/components/BaseDialog'
  import ClientLink from '@/app/core/components/ClientLink'
  import ZoneLink from '@/app/core/components/ZoneLink'
  import HostLink from '@/app/core/components/HostLink'
  import DomainDnssecData from './DomainDnssecData'
  import NameValueBlock from '@/app/core/components/NameValueBlock'
  import RegistryTypeLink from '@/app/core/components/RegistryTypeLink'
  import OrderDialog from '@/app/core/components/Order/OrderDialog'
  import SecretText from '@/app/core/components/SecretText'

  import {createContactDataObject} from '@/app/pages/Contact/constants'
  import {DOMAIN_STATES} from '@/app/core/components/RegistryObject/StatesDialog'

  import {typeProp, TYPES} from './ApplicationSupport'

  import ContactCard from './components/ContactCard'
  import DataCard from '@/app/core/components/DataCard'
  import {getLockStatusLabel} from './constants'

  const statesFlat = [
    ...DOMAIN_STATES.client,
    ...DOMAIN_STATES.extended
  ]

  export default {
    name: 'DomainData',

    components: {
      DataCard,
      BaseDialog,
      ContactCard,
      DomainDnssecData,
      NameValueBlock,
      OrderDialog
    },

    mixins: [registryCommands],

    props: {
      domain: {
        type: Object,
        default: undefined
      },
      type: typeProp,
      registryMetadata: {
        type: Object,
        default: () => ({})
      },
      history: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        TYPES,
        hostIps: false,
        dnssecDialog: false,
        showAllVariants: false
      }
    },

    computed: {

      ...mapGetters ('auth', [
        'mayManageAllEntities'
      ]),

      variants () {
        const variants = this.domain.extraData.variants || []

        return this.showAllVariants ? variants : variants.slice (0, 7)
      },

      launchPhaseText () {
        const lp = this.domain.launchPhase
        return lp && (lp.subname ? `${lp.name} - ${lp.subname}` : lp.name)
      },

      basicData () {
        return [
          {
            name: this.$t ('view.label.state'),
            value: this.$t (
              this.type === TYPES.APPLICATION
                ? `view.label.applicationState.${this.domain.status}`
                : `view.label.${this.domain.deleted ? 'deleted' : 'active'}`)
          },
          ...[
            this.type === TYPES.APPLICATION && this.domain.statusMsg
              ? {
                name: this.$t ('view.label.statusMsg'),
                value: this.domain.statusMsg
              }
              : {}
          ],
          {
            name: this.$t ('view.label.registryType'),
            value: true,
            slotValue: this.$createElement (RegistryTypeLink, {
              props: {
                value: this.domain.registryId,
                short: true
              }
            })
          },
          ...[
            this.domain.launchPhase
              ? {
                name: this.$t ('create.label.launchPhase'),
                value: this.launchPhaseText
              }
              : {}
          ],
          ...[
            'handle',
            'language',
            'maintainer'
          ].map (prop => (
            {
              name: this.$t (`view.label.${prop}`),
              value: this.domain[prop] || EmptyMark
            }
          )),
          {
            name: this.$t ('label.client'),
            value: !!this.domain.clientId || EmptyMark,
            ...(
              this.domain.clientId
                ? {
                  slotValue: this.$createElement (ClientLink, {
                    props: {
                      id: this.domain.clientId
                    }
                  })
                }
                : {}
            )
          },
          ...[
            'creator',
            'updater'
          ].map (type => ({
            name: this.$t (`label.${toCamelCase (type, 'client')}`),
            value: !!this.domain[toCamelCase (type, 'memberId')] || EmptyMark,
            ...(
              this.domain[toCamelCase (type, 'memberId')]
                ? {
                  slotValue: this.$createElement (ClientLink, {
                    props: {
                      id: this.domain[toCamelCase (type, 'memberId')]
                    }
                  })
                }
                : {}
            )
          })),
          ...(
            this.domain.authInfo
              ? [{
                name: this.$t ('view.label.authInfo'),
                value: true,
                slotValue: this.$createElement (SecretText, {
                  props: {
                    value: this.domain.authInfo
                  }
                })
              }]
              : []
          ),

          {
            name: this.$t ('view.label.linkedZone'),
            value: true,
            slotValue: this.domain.extraData.linkedZoneId
              ? this.$createElement (ZoneLink, {
                props: {
                  vid: this.domain.extraData.linkedZoneId
                }
              })
              : this.$createElement ('span', EmptyMark)
          },

          ...this.supports (this.domain.registryId, 'DomainLockModify')
            ? [
              {
                name: this.$t ('view.label.locking.lockStatus'),
                value: this.$t (getLockStatusLabel (this.domain.lockStatus))
              }
            ]
            : []
        ]
      },

      lifecycle () {
        const lp = this.domain.launchPhase
        return [
          ...(lp
            ? [{
              name: this.$t ('create.label.launchPhase'),
              value: lp.subname ? `${lp.name} - ${lp.subname}` : lp.name
            }]
            : []),
          ...[
            'creationDate',
            'updateDate',
            'transferDate',
            'expirationDate',
            'paidUntil'
          ].map (d => (
            {
              name: this.$t (`label.${d}`),
              value: this.domain[d]
                ? this.formatDate (this.domain[d])
                : EmptyMark
            }
          )),
          ...(this.mayManageAllEntities
            ? ['argpEnd'].map ((i) => ({
              name: this.$t (`label.${i}`),
              value: this.domain[i]
                ? this.formatDate (this.domain[i])
                : EmptyMark
            }))
            : [])
        ]
      },

      contacts () {
        return this.domain.contacts.map (c => {
          const cExt = this.domain.extraData.contacts[c.handle]
          return {
            ...c,
            ...(cExt
              ? createContactDataObject (cExt, c.role, false)
              : {status: 'foreign'})
          }
        })
      },

      hosts () {
        return this.domain.hosts.map ((h, idx) => (
          {
            name: h.name,
            value: h.ips.length > 0
              ? h.ips
              : this.$t ('view.noIpAddresses'),
            extraKey: h.name + idx,
            keySlotValue: h.versionId
              ? this.$createElement (HostLink, {
                props: {
                  host: h
                }
              })
              : this.$createElement ('span', h.name)
          }
        ))
      },

      domainStates () {
        let states = []

        if (this.domain?.states?.length) {
          states = [...this.domain.states].sort ((a, b) =>
            statesFlat.indexOf (a) - statesFlat.indexOf (b)).map (it =>
            ({name: it, value: ' '}))
        }

        return states
      },

      addOn () {
        const addOnData = []
        const fields = this.domain.addOnFields

        for (const field in fields) {
          if (fields.hasOwnProperty (field)) {
            addOnData.push ({
              name: 'addon' + field,
              value: fields[field]
            })
          }
        }

        return addOnData
      },

      privacy () {
        const privacyData = []
        const registrantType = this.domain.registrantType

        if (registrantType) {
          privacyData.push ({
            name: this.$t ('privacy.registrantType.label'),
            value: this.$t (`privacy.registrantType.${registrantType}`)
          })

          const publishRegistrantData = this.domain.publishRegistrantData

          if (publishRegistrantData !== null) {
            privacyData.push ({
              name: this.$t ('privacy.data.disclose.label'),
              value: publishRegistrantData
            })
          }
        }

        return privacyData
      },

      registrySpecific () {
        const registrySpecific = []
        const fields = this.domain.specificFields

        for (const field in fields) {
          if (fields.hasOwnProperty (field)) {
            registrySpecific.push ({
              name: field,
              value: fields[field]
            })
          }
        }

        return registrySpecific
      },

      optionalSections () {
        return [
          {l: 'addOn', i: this.addOn, show: this.addOn.length > 0},
          {l: 'privacy', i: this.privacy, show: this.privacy.length > 0},
          {
            l: 'registrySpecific',
            i: this.registrySpecific,
            show: this.registrySpecific.length > 0
          }
        ]
      },

      providerChains () {
        return [{
          name: this.domain.providerChainType,
          value: this.domain.providerChainSpec
        }]
      }
    }
  }
</script>
