<template>
  <router-link
    :to="{name: 'host.view.version', params: {vid: host.versionId}}"
    v-text="host.name"/>
</template>

<script>

  export default {
    name: 'HostLink',

    props: {
      host: {
        type: Object,
        required: true
      }
    }
  }
</script>
