<template>
  <router-link
    v-if="mayViewZone"
    :to="{name: 'zone.view.version', params: {vid}}"
    v-text="zoneId || vid"/>
  <span v-else>
    {{ zoneId || vid }}
  </span>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'ZoneLink',

    props: {
      vid: {
        type: Number,
        required: true
      }
    },

    data () {
      return {
        zoneId: '',
        zoneClientId: ''
      }
    },

    computed: {
      ...mapGetters ({
        mayViewObject: 'auth/mayViewObject'
      }),

      mayViewZone () {
        return this.mayViewObject (this.zoneClientId)
      }
    },

    watch: {
      vid (newValue) {
        this.fetchZoneData (newValue)
      }
    },

    created () {
      this.fetchZoneData (this.vid)
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * Fetch the data of the zone with the given version ID
       *
       * @param {Number} vid     the zone version ID
       */
      fetchZoneData (vid) {
        this.fetchData ({
          op: 'zone/loadVersion',
          params: {
            vid
          },
          cb: data => {
            this.zoneClientId = data.zoneData.clientId
            this.zoneId = data.zoneData.handle
          }
        })
      }
    }
  }
</script>
